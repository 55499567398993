<script setup lang="ts">
// components
import AppDropdownList from './AppDropdownList.vue';
import AppDropdownButton from './AppDropdownButton.vue';
import AppDropdownListItem from './AppDropdownListItem.vue';
import AppInput from '~/components/redesign/AppInput.vue';

// directives
import { vOnClickOutside } from '@vueuse/components';

// utils
import get from 'lodash.get';
import { isNotEmptyString, isString } from '~/utils';

// types
import type {
  AppDropdownProps,
  AppDropdownSlots,
  AppDropdownModelValue,
} from './AppDropdown.types';

const props = withDefaults(
  defineProps<AppDropdownProps>(),
  {
    searchable: false,
    options: () => [],
  },
);

const focused = ref<boolean>(false);
const setFocused = (value: boolean) => {
  focused.value = value;
};

const modelValue = defineModel<AppDropdownModelValue>('modelValue');
const setModelValue = (value: AppDropdownModelValue) => {
  modelValue.value = value;
  setFocused(false);
};

const searchRef = useTemplateRef<InstanceType<typeof AppInput>>('search');
const searchQuery = ref<string>('');
const filteredOptions = computed(() =>
  props.options
    .filter(option => {
      const searchValue = get(option, props.keyAttribute, option);

      if (isString(searchValue)) {
        return searchValue
          .toLowerCase()
          .includes(searchQuery.value.toLowerCase());
      }

      return option;
    }),
);

defineSlots<AppDropdownSlots>();

watchEffect(async () => {
  if (
    !props.searchable ||
    !focused.value ||
    !searchRef.value
  ) return;

  await nextTick();
  searchRef.value.focus();
});
</script>

<template>
  <div
    v-on-click-outside="() => setFocused(false)"
    data-component-name="AppDropdown.Base"
    class="base"
    @keydown.esc="setFocused(false)"
  >
    <div class="wrapper">
      <AppDropdownButton
        v-model:focused="focused"
        :has-value="Boolean(modelValue)"
        :label
        :size
        :fill
        :required
        :disabled
        :error
      >
        <slot :value="modelValue" name="value">
          {{ get(modelValue, keyAttribute, modelValue) }}
        </slot>
      </AppDropdownButton>

      <span v-if="!disabled && isNotEmptyString(error)" class="error">
        {{ error }}
      </span>

      <transition name="fade">
        <AppDropdownList
          v-show="focused"
          :options="filteredOptions"
          @click="setModelValue"
        >
          <template v-if="searchable" #prepend>
            <AppInput
              v-model="searchQuery"
              ref="search"
              :placeholder="
                isNotEmptyString(searchPlaceholder)
                  ? searchPlaceholder
                  : $t('Enter_search_query')
              "
              type="search"
            />
          </template>

          <template #item="{ value }">
            <slot :value name="item">
              <AppDropdownListItem>
                {{ get(value, keyAttribute, value) }}
              </AppDropdownListItem>
            </slot>
          </template>
        </AppDropdownList>
      </transition>
    </div>
  </div>
</template>

<style
  src="./AppDropdown.scss"
  lang="scss"
  scoped
/>
