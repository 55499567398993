<script setup lang="ts">
// components
import SvgIcon from '~/components/SvgIcon.vue';
import Buttons from '~/components/redesign/Buttons';

// utils
import { isNotEmptyString } from '~/utils';

// types
import type {
  AppDropdownButtonProps,
  AppDropdownButtonSlot,
} from './AppDropdownButton.types';

const props = withDefaults(
  defineProps<AppDropdownButtonProps>(),
  {
    size: 'large',
    fill: 'solid',
    hasValue: false,
    required: false,
    disabled: false,
  },
);

defineSlots<AppDropdownButtonSlot>();

const focused = defineModel<boolean>('focused', {
  default: false,
});

const toggleFocused = () => {
  if (props.disabled) return;
  focused.value = !focused.value;
};
</script>

<template>
  <Buttons.Regular
    :class="[
      fill,
      {
        'has-focus': focused,
        'has-value': hasValue,
        'has-disabled': disabled,
        'has-error': isNotEmptyString(error)
      },
    ]"
    :size
    :disabled
    fill="none"
    accent="black"
    type="button"
    data-component-name="AppDropdown.Button"
    @click="toggleFocused"
  >
    <label v-if="isNotEmptyString(label)">
      {{ label + (required ? '*' : '') }}
    </label>

    <span class="value">
      <slot />
    </span>

    <SvgIcon name="chevron-down" />
  </Buttons.Regular>
</template>

<style scoped lang="scss">
@use "_/mixins/typo";
@use "_/mixins/flex";
@use "_/mixins/size";
@use "_/mixins/text-transform";
@use "_/fn";

@mixin applyToBase {
  &:deep([data-component-name="Buttons.Base"]) {
    @content;
  }
}

[data-component-name="AppDropdown.Button"] {
  @include size.full;
  position: relative;
  border: 1px solid transparent;

  @include applyToBase {
    @include flex.center-sb;

    label {
      @include typo.body-3;
      color: fn.token('text-secondary');

      pointer-events: none;

      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      transition: all 0.15s ease;
    }

    .value {
      @include typo.body-3;
      @include text-transform.truncate-to-rows(1);
      color: fn.token('text-primary');

      text-align: start;
    }

    svg {
      @include size.fixed(1.5rem);

      transition: transform 0.15s ease;
    }
  }

  // FILL
  &.solid {
    background-color: fn.token('surf-cont-secondary');
  }

  &.outline {
    border-color: fn.token('outline-secondary');
  }

  // STATE
  &.has-focus {
    border-color: fn.token('outline-action');

    @include applyToBase {
      svg {
        transform: rotate(-180deg);
      }

      &:hover,
      &:active {
        background-color: unset;
      }
    }
  }

  &.has-focus,
  &.has-value {
    label {
      top: 0;
      padding: 0 0.25rem;

      @include typo.caption;
      color: fn.token('text-primary');
      background-color: fn.token('surf-cont-primary');
    }
  }

  &.has-error {
    border-color: fn.token('outline-error');
  }

  &.has-disabled {
    &.solid {
      border-color: transparent;
    }

    &.outline {
      border-color: fn.token('outline-secondary');
    }

    @include applyToBase {
      label,
      .value {
        color: fn.token('text-tertiary');
      }

      svg {
        fill: fn.token('icon-tertiary');
      }
    }
  }
}
</style>
